export const convertNoteHTML = (payload: string) => {
    const div = document.createElement('div')
    div.innerHTML = payload
    const mentions = div.getElementsByClassName('mention')
    const tags = div.getElementsByClassName('tag')

    for (let i = 0, max = mentions.length; i < max; i++) {
        const tagId = (mentions[i] as HTMLElement).dataset.tag
        mentions[i].innerHTML = `@${tagId}`
    }
    for (let i = 0, max = tags.length; i < max; i++) {
        const tagId = (tags[i] as HTMLElement).dataset.tag
        const tagName = (tags[i] as HTMLElement).dataset.name

        if (tagId === '-1') {
            tags[i].innerHTML = `#${tagName}`
        } else {
            tags[i].innerHTML = `{${tagId}`
        }
    }

    const text = div.innerHTML.replace(/(<([^>]+)>)/gi, ' ')
    const textNoDoubleSpaces = text.replace(/ +(?= )/g, '')
    return textNoDoubleSpaces.trim()
}
